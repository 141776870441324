<template>
    <v-container>
        <v-row>
            <v-breadcrumbs large :items="items_nav">
                <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
                </template>
                <template v-slot:item="{ item }">
                <v-breadcrumbs-item :disabled="item.disabled">
                    {{ item.text }}
                </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-row>
        <v-card elevation="4">
            <v-card-title>
                <v-row>
                    <div class="col">
                        Notificação manual
                    </div>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <v-text-field
                                :rules="tituloRules"
                                v-model="notificacao.titulo"
                                counter="50"
                                maxlength="50"
                                label="Título"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <v-textarea
                                solo
                                v-model="notificacao.texto"
                                label="Mensagem"></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-action class="d-flex flex-row-reverse">
                <div class="ma-4">
                        <v-btn
                            color="success"
                            dark
                            @click="sendNotificacao"
                            >
                            ENVIAR
                        </v-btn>
                    </div>
            </v-card-action>
        </v-card>

        <v-dialog v-model="dialogAlert" hide-overlay width="300">
          <v-card class="mx-auto" dark>
            <v-card-title>
              <span class="title font-weight-bold">{{dialogContent.title}}</span>
            </v-card-title>
            <v-card-text>
              {{dialogContent.message}}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="white"
                    text
                    @click="dialogAlert = false"
                >
                    ENTENDI
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            items_nav: [
                {
                    text: "Sistema",
                    disabled: false,
                },
                {
                    text: "Notificação",
                    disabled: true,
                },
                {
                    text: "Notificar",
                    disabled: true,
                }
            ],
            notificacao: {
                titulo: "",
                texto: ""
            },
            dialogAlert: false,
            dialogContent: {
                title: "",
                message: ""
            }
        }
    },
    methods: {
        showAlert: function(title, content) {
            this.dialogContent.title = title;
            this.dialogContent.message = content;
            this.dialogAlert = true;
        },
        sendNotificacao: async function() {
            if(this.notificacao.titulo == "" ) {
                this.showAlert('Notificação inválida', 'Notificação precisa de título!');
                return;
            }
            if(this.notificacao.texto == "" ) {
                this.showAlert('Notificação inválida', 'Notificação precisa de mensagem!');
                return;
            }
            try {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(this.notificacao)
                };
                let response = await fetch(
            "https://us-central1-festit-c0636.cloudfunctions.net/api/notification/send", requestOptions);
                if(response.status == 200) {
                    this.notificacao.titulo = "";
                    this.notificacao.texto = "";
                    var result = await response.json();
                    this.showAlert('Notificação enviada', result.message);       
                } else {
                    this.showAlert('Operação falhou', "Tente novamente mais tarde!");       
                }
            }  catch (error) {
                this.showAlert('Ocorreu um problema na comunicação', 'Tente novamente mais tarde!');
            }
        }
    },
}
</script>

<style>

</style>